body{
	width: 100%;
}
@font-face {
	font-family: "Montserrat Medium";
	src: url("../fonts/Montserrat-Medium.ttf");
}
$dark-text-color:#292d33;
$border-color:#e7e7e7;
$placeholder-color:#d1d5da;
$dark-blue:#292D33;
@mixin placeholder($placeholder-color){
	::-webkit-input-placeholder {color:$placeholder-color;}
	::-moz-placeholder          {color:$placeholder-color;}/* Firefox 19+ */
	:-moz-placeholder           {color:$placeholder-color;}/* Firefox 18- */
	:-ms-input-placeholder      {color:$placeholder-color;}
	input[placeholder]          {text-overflow:ellipsis;}
	input::-moz-placeholder     {text-overflow:ellipsis;}
	input:-moz-placeholder      {text-overflow:ellipsis;}
	input:-ms-input-placeholder {text-overflow:ellipsis;}
	::-webkit-input-placeholder       {opacity: 1; transition: opacity 0.3s ease;}
	::-moz-placeholder                {opacity: 1; transition: opacity 0.3s ease;}
	:-moz-placeholder                 {opacity: 1; transition: opacity 0.3s ease;}
	:-ms-input-placeholder            {opacity: 1; transition: opacity 0.3s ease;}
	:focus::-webkit-input-placeholder {opacity: 0; transition: opacity 0.3s ease;}
	:focus::-moz-placeholder          {opacity: 0; transition: opacity 0.3s ease;}
	:focus:-moz-placeholder           {opacity: 0; transition: opacity 0.3s ease;}
	:focus:-ms-input-placeholder      {opacity: 0; transition: opacity 0.3s ease;}
}
@mixin border-radius($radius:3px) {
	-webkit-border-radius: $radius;
	-moz-border-radius: $radius;
	-ms-border-radius: $radius;
	border-radius: $radius;
}
// @include border-radius(10px);


/*sprite*/
.sprite {
	background-image: url(../img/investor/sprite.png);
	background-repeat: no-repeat;
	display: inline-block;
}

.sprite-arraw {
	width: 7px;
	height: 7px;
	background-position: -5px -5px;
	-webkit-transform: rotate(135deg);
	-ms-transform: rotate(135deg);
	transform: rotate(135deg);
}

.sprite-arraw-down {
	width: 7px;
	height: 7px;
	background-position: -22px -5px;
	-webkit-transform: rotate(225deg);
	-ms-transform: rotate(225deg);
	transform: rotate(225deg);
}


.sprite-arraw-right {
	display: inline-block;
	width: 8px;
	height: 8px;
	background-image: url("../img/investor/arraw-right.svg");
	background-repeat: no-repeat;
	-webkit-transform: rotate(135deg);
	-ms-transform: rotate(135deg);
	transform: rotate(135deg);
}

.sprite-arraw-grey {
	display: inline-block;
	width: 4px;
	height: 6px;
	background-image: url("../img/investor/icon_breadcrumb.svg");
	background-repeat: no-repeat;
}

.sprite-arraw-grey-down{
	display: inline-block;
	width: 9px;
	height: 6px;
	background-image: url("../img/investor/small-arrow-down-icon.svg");
	background-repeat: no-repeat;
}
.sprite-close{
	display: inline-block;
	display: none;
	width: 18px;
	height: 18px;
	background-image: url("../img/investor/icon-close.svg");
	background-repeat: no-repeat;
}


.sprite-icon-check-false {
	width: 16px;
	height: 16px;
	background-image: url("../img/investor/chack-false.svg");
	background-repeat: no-repeat;
}

.sprite-icon-check-true {
	width: 16px;
	height: 16px;
	background-image: url("../img/investor/chack-true.svg");
	background-repeat: no-repeat;
}

.sprite-icon-folder {
	display: inline-block;
	width: 67px;
	height: 83px;
	background-image: url("../img/investor/icon_folder.svg");
	background-repeat: no-repeat;
}

.sprite-icon-styrelse {
	display: inline-block;
	width: 72px;
	height: 88px;
	background-image: url("../img/investor/icon_board.svg");
	background-repeat: no-repeat;
}

.sprite-icon-styrelse1 {
	display: inline-block;
	width: 72px;
	height: 88px;
	background-image: url("../img/investor/icon_management.svg");
	background-repeat: no-repeat;
}

.sprite-icon-bolagsort {
	display: inline-block;
	width: 84px;
	height: 84px;
	background-image: url("../img/investor/icon_document.svg");
	background-repeat: no-repeat;
}

.sprite-rectangle-533 {
	width: 16px;
	height: 16px;
	background-position: -5px -196px;
}

.sprite-white-sonetel-icon {
	width: 122px;
	height: 36px;
	background-position: -31px -196px;
}

/*sprite end */

.parent{
	overflow: hidden;
	white-space: nowrap;
	font-size: 0;
	&:before {
		height: 100%;
		width: 0px;
		display: inline-block;
		vertical-align: middle;
		content: '';
	}
}
.vertical-align-el{
	vertical-align: middle;
	display: inline-block;
	white-space: normal;
	font-size: 13px;
}


/* end vertical-align */
.investro{
	.modal-window{
		width:100%;
		max-width: 1680px;
		height: 100%;
		.md-modal {
			position: fixed;
			top: 50%;
			left: 50%;
			width: 100%;
			max-width: 1680px;
			height: 100%;
			z-index: 9999;
			background: #fff;
			text-align: center;
			.md-content{
				border: solid 0px $border-color;
				height: 100%;
			}
			.top{
				padding-top: 40px;
				.logo {
					float: left;
					text-align: left;
					padding-left: 40px;
					width: 50%;
					a{
						background-image: url(../img/header/logo-dark.svg);
						display: inline-block;
						height: 37px;
						width: 123px;
					}
				}
				.close{
					float: right;
					width: 50%;
					text-align: right;
					padding-right: 40px;
					cursor:pointer;
					span{
						//background-image: url(../img/investor/esc.png);
						background-image: url(../img/investor/icon-close.svg);
						background-repeat: no-repeat;
						display: inline-block;
						height: 37px;
						width: 25px;
						position: relative;
						top: 4px;
						&:after{
							content:"esc";
							display: inline-block;
							position: absolute;
							top:20px;
							left:-4px;
							color: #a3a3b4;
							font-family: "Montserrat Medium";
							font-size: 11px;
							font-weight: 300;
							letter-spacing: 2px;
							line-height: 15px;
							text-transform: uppercase;

						}
					}
				}
				&:after{
					content:"";
					display: block;
					clear: both;
				}
			}
			h3{
				margin-bottom: 0px;
				padding-left: 15px;
				padding-right: 15px;
				color: $dark-text-color;
				font-family: "Montserrat Regular";
				font-size: 32px;
				font-size: 32px;
				font-weight: 400;
				line-height: 44px;
			}
			p{
				margin-top: 7px;
				padding-bottom: 22px;
				padding-left: 15px;
				padding-right: 15px;
				color: #7e7e94;
				font-family: "Montserrat Regular";
				font-size: 17px;
				font-size: 20px;
				font-weight: 400;
				line-height: 32px;
			}
			.checkbox-block{
				margin-bottom: 34px;
				input[type="checkbox"] {
					display: none;
					//display:inline-block;
					height: 20px;
					width: 20px;
					border: solid 1px #ccc;
				}
				label {
					display: inline-block;
					cursor: pointer;
					position: relative;
					padding-left: 25px;
					margin-right: 15px;
					color: $dark-text-color;
					font-family: "Montserrat Regular";
					font-size: 17px;
					font-weight: 400;
					line-height: 16px;
				}
				label:before{
					content: "";
					display: inline-block;
					position: absolute;
					top: -2px;
					left: 0;
					display: block;
					width: 16px;
					height: 16px;
					background-image: url("../img/investor/chack-false.svg");
					background-repeat: no-repeat;
					display: inline-block;
				}
				input[type="checkbox"]:checked + label:before{
					width: 16px;
					height: 16px;
					background-image: url("../img/investor/chack-true.svg");
					background-repeat: no-repeat;
				}
			}
			input[type="text"] {
				display: block;
				margin: 0 auto;
				margin-bottom: 16px;
				padding-left: 16px;
				width: 400px;
				max-width: 90%;
				//height: 64px;
				//padding: 19px 16px;
				line-height: 60px;
				vertical-align: middle;
				border-radius: 2px;
				@include border-radius(2px);
				@include placeholder(#d1d5da);
				border: 1px solid #d1d5da;
			}
			input::-webkit-input-placeholder { /* WebKit browsers */
				line-height: 60px;
				vertical-align: middle;
			}
			input:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
				line-height: 60px;
				vertical-align: middle;
			}
			input:-ms-input-placeholder { /* Internet Explorer 10+ */
				line-height: 60px;
				vertical-align: middle;
			}
			input::-moz-placeholder { /* Mozilla Firefox 19+ */
				line-height: 60px;
				vertical-align: middle;
			}
			.button{
				display: inline-block;
				margin-bottom: 100px;
				width: 400px;
				max-width: 90%;
				height: 64px;
				line-height: 64px;
				@include border-radius(2px);
				border: solid 0px #fb2d69;
				outline: solid 0px #fb2d69;
				background-color: #fb2d69;
				cursor: pointer;
				color: #ffffff;
				font-family: "Montserrat SemiBold";
				font-size: 14px;
				font-weight: 300;
				letter-spacing: 2px;
				text-transform: uppercase;
			}
		}
		.md-show ~ .md-overlay {
			opacity: 0.2;
			opacity: 0;
			visibility: visible;
		}

	}
	.dark-top .top.wrapper {
		background-color: #ddd;
	}
	h2.title{
		color: $dark-text-color;
		font-family: "Montserrat Regular";
		font-size: 32px;
		font-weight: 400;
		line-height: 44px;
		margin-top: -3px;
		margin-top: 10px;
		margin-bottom: -2px;
	}
	.top.wrapper{
		min-height: 540px;
		.dark-overlay {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background-color: rgba(36, 39, 45, 0.25);
		}
		.nav{
			height:80px;
		}
		.hero.container{
			padding: 0;
			margin:0;
			min-height: 460px;
			height: 460px;
			white-space: nowrap;
			text-align: center;
			font-size: 0;
			margin: 0 auto;
			&:before {
				height: 100%;
				display: inline-block;
				vertical-align: middle;
				content: '';
			}
			.content{
				float:none;
				margin-top: -80px;
				display: inline-block;
				white-space: normal;
				vertical-align: middle;
				text-align: center;
			}


			h1{
				color: #ffffff;
				font-family: "Montserrat Regular";
				font-size: 48px;
				font-weight: 400;
				line-height: 48px;
				margin-bottom: 15px;
			}
			.subheader{
				font-family: "SF NS Display";
				font-family: "Montserrat Regular";
				font-size: 24px;
				font-weight: 400;
				line-height: 32px;
			}
		}
	}
	.block-link{
		background: #fff;
		border-bottom: solid 1px $border-color;
		z-index: 1000;
		background: #fff;
		width: 100%;
		.togle-button{
			display: none;
		}
		.mobile-menu-block{
			display: none;
		}
		.menu-block{
			display: block;
			padding: 18px 0;
			.menu{
				list-style-type: none;
				li{
					display: inline-block;
					a{
						padding-right: 32px;
						line-height: 11px;
						text-transform: uppercase;
						font-family: "Montserrat Medium";
						font-family: "Montserrat SemiBold";
						font-size: 10px;
						font-weight: 600;
						line-height: 10px;
						letter-spacing: 2px;
						color: #7C7C92;

					}
					&.active{
						a{
							color: #292D33;
						}
					}
				}
			}
		}
	}
	.block1{
		padding-bottom: 30px;
		padding-bottom: 0px;
		.container{
			padding-top: 120px;
		}
		.content-text{
			box-sizing: border-box;
			display: block;
			float: left;
			width: 40%;
			max-width: 335px;
			.p1{
				color: #7e7e94;
				font-family: "Montserrat Regular";
				font-size: 17px;
				font-weight: 400;
				line-height: 24px;
				padding-top: 7px;
			}

		}
		.info-block{
			overflow: hidden;
			box-sizing: border-box;
			border: solid 1px #e7e7e7;
			width: 57%;
			float: right;
			//margin-top:120px;
			.inner-info-block{
				overflow: hidden;
				margin: 40px 0px;
				.left{
					float:left;
					width:55%;
					border-right: solid 2px $border-color;
					height: 181px;
					padding-left: 60px;
					.p11{
						color: $dark-text-color;
						font-family: "Montserrat Regular";
						font-size: 17px;
						font-weight: 500;
						padding-top: 19px;
					}
					.p0{
						margin-top: -5px;
						margin-bottom: -5px;
						color: $dark-text-color;
						font-family: "Montserrat Regular";
						font-size: 48px;
						font-weight: 400;
						text-transform: uppercase;
					}
					.p1{
						color: #7e7e94;
						font-family: "Montserrat Regular";
						font-size: 17px;
						font-weight: 400;
					}
				}
				.right{
					height: 181px;
					float:left;
					width:45%;
					text-align: center;

					.p11{
						margin-top: 20px;
						margin-bottom: 0px;
						color: $dark-text-color;
						font-family: "Montserrat Regular";
						font-size: 17px;
						font-weight: 500;

					}
					.text-blue{
						margin-top: 0px;
						margin-bottom: 27px;
						color: #68d0d0;
						font-family: "Montserrat Regular";
						font-size: 17px;
						font-weight: 300;
					}
					.p1{
						//margin: 0px;
						color: $dark-text-color;
						font-family: "Montserrat Regular";
						font-size: 17px;
						font-weight: 500;
					}
					.text-number{
						margin-top: 0px;
						color: $dark-text-color;
						font-family: "Montserrat Regular";
						font-size: 17px;
						font-weight: 300;
					}
				}
				.mobile-right{
					display: none;
				}

			}
		}
	}
	.block2{
		padding-top: 67px;
		.seeall{
			margin-top: 67px;
			margin-bottom: -36px;
			margin-bottom: -47px;
			padding-right: 6px;
			text-align: right;
			color: #ff0850;
			font-family: "Montserrat SemiBold";
			letter-spacing: 2px;
			text-transform: uppercase;
			font-size: 12px;
			font-weight: 600;
			a{
				span {
					position: relative;
					left: 0px;
					top:0px;
					transition: .2s all linear;
				}
				&:hover span{
					left:6px;
				}
			}

		}
		h2.title{
			margin-bottom: 32px;
		}
		.inner-container{
			text-align: left;
			font-size: 0;
			margin-left: -27px;
			margin-left: -16px;
			.block-element-wrapper{
				display: inline-block;
				box-sizing: border-box;
				text-align: center;
				width: 292px;
				height: 298px;
				margin-left: 5px;
				margin-bottom: 0px;
				padding-top:6px;
				overflow: hidden;
			}
			.block-element{
				display: inline-block;
				box-sizing: border-box;
				width: 272px;
				height: 278px;
				border: 1px solid $border-color;
				text-align: center;
				transition-duration: 0.7s;
				transition-property: box-shadow;
				span{
					margin-top: 64px;
				}
				.p11{
					color: $dark-text-color;
					font-family: "Montserrat Regular";
					font-family: "Montserrat Medium";
					font-size: 17px;
					font-weight: 500;
					//line-height: 21px;
					line-height: 0px;
					padding-top: 0px;

				}
				.p1{
					color: #7e7e94;
					font-family: "Montserrat Regular";
					font-size: 17px;
					font-weight: 400;
					//margin-top:-5px;
				}
				&:last-child{
					margin-right: 0;
				}
				&:hover{
					border:solid 0px $border-color;
					margin-top: 1px;
					-webkit-box-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.15);
					-moz-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.15);
					box-shadow: 0 5px 20px rgba(0, 0, 0, 0.15);
				}
			}
		}
	}
	.block3{
		padding-top:41px;
		.seeall-block-mob{
			display: none;
		}
		.seeall{
			margin-top: 70px;
			margin-bottom: -39px;
			margin-top: 75px;
			margin-bottom: -46px;
			padding-right: 6px;
			text-align: right;
			color: #ff0850;
			font-size: 12px;
			text-transform: uppercase;
			//font-family: "Montserrat Medium";
			font-family: "Montserrat SemiBold";
			letter-spacing: 2px;
			font-weight: 600;
			a{
				span {
					position: relative;
					left: 0px;
					top:0px;
					transition: .2s all linear;
				}
				&:hover span{
					left:6px;
				}
			}
		}
		h2.title{
			margin-bottom: 50px;
		}
		.inner-container{
			text-align: left;
			margin-left:-20px;
			.block-element{
				display: inline-block;
				margin-right: 0%;
				margin-left: 20px;
				width: 275px;
				width: 278px;
				margin-bottom: 38px;
				text-align: left;
				vertical-align: top;
				.text{
					float:left;
					width: 225px;
					color: $dark-text-color;
					font-family: "Montserrat Medium";
					font-size: 17px;
					font-weight: 500;
					line-height: 24px;
					//max-height: 50px;
					margin-bottom: 5px;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: normal;
				}
				.date{
					font-family: "Montserrat Regular";
					font-size: 17px;
					font-weight: 400;
					color: $dark-text-color;
				}
				.right{
					float: right;
					margin-right: -5px;
					width: 15px;
					margin-top: -2px;
					cursor: pointer;
					div{
						span{
							margin-left: 0px;
							transition-property: margin-left;
							transition-duration: 0.2s;
						}
					}
				}
				.date:before{
					display: block;
					content:"";
					clear: both;
				}
				.link-block-element{
					display: block;
					height: 100%;
					width: 100%;
					&:hover{
						.right{
							div{
								span{
									margin-left: 6px;
								}
							}

						}
					}
				}
			}
		}

	}
	.block4{
		padding-top: 115px;
		h2.title{
			padding-bottom: 36px;
		}
		.inner-container{
			text-align: left;
			font-size: 0px;
			margin-left: -22px;
			.block-element-wrapper{
				display: inline-block;
				box-sizing: border-box;
				text-align: center;
				width: 292px;
				height: 330px;
				margin-left: 7px;
				margin-bottom: 0px;
				padding-top:6px;
				overflow: hidden;
			}
			.block-element{
				display: inline-block;
				width: 272px;
				height: 310px;
				margin-left: 16px;
				margin-left: 0px;
				border: 1px solid $border-color;
				background-color: #ffffff;
				text-align: center;
				transition-duration: 0.7s;
				transition-property: box-shadow;
				.img-block{
					padding-top: 40px;
					.sprite-icon-bolagsort {
						margin-bottom: 4px;
					}
					span{
						display: inline-block;
					}
				}
				.p11{
					color: $dark-text-color;
					font-family: "Montserrat Medium";
					font-size: 17px;
					font-weight: 500;
					line-height: 21px;
				}
				.p1{
					color: #7e7e94;
					font-family: "Montserrat Regular";
					font-size: 17px;
					font-weight: 400;
					max-width: 213px;
					margin: 0 auto;
					margin-top: -5px;
					line-height: 22px;
				}
				&:last-child{
					margin-right: 0;
				}
				&:hover{
					border:solid 0px $border-color;
					margin-top: 1px;
					-webkit-box-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.15);
					-moz-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.15);
					box-shadow: 0 5px 20px rgba(0, 0, 0, 0.15);
				}

			}
		}

	}
	.block5-table{
		.mobile-table-container{
			display: none;
		}
		padding-top: 71px;
		padding-top: 79px;
		padding-bottom: 117px;
		h2.title{
			padding-bottom: 36px;

		}
		.inner-container{
			margin:0 10px;
			table{
				width: 100%;
				tr{
					td{
						height: 48px;
						padding: 0.5rem 0;
						font-weight: normal;
						color: $dark-text-color;
						span a{
							color: #7e7e94;
							font-family: "Montserrat Regular";
							font-size: 17px;
							font-weight: 400;
							line-height: 24px;
							text-decoration: underline;
						}
					}
					&:nth-child(2n) {
						background-color: #fbfbfc;
					}
				}
				.active{
					background-color: #fbfbfc;
				}
				.col1{
					width: 190px;
					padding-left: 10px;
					font-size: 17px;
					font-weight: 500;
					line-height: 24px;
					font-family: "Montserrat Medium";
				}
				.col2{
					font-size: 17px;
					font-weight: 400;
					line-height: 24px;
				}
				.col3{
					width:80px;
					text-align: right;
				}
				.col4{
					width:60px;
					text-align: right;
				}
				.col5{
					width:93px;
					padding-right: 16px;
					text-align: right;
				}
			}
			.mobile-table{
				display: none;
			}
		}
		.more{
			display:block;
			margin: 0 auto;
			margin-top: 30px;
			margin-top: 48px;
			width: 100px;
			height: 50px;
			font-family: "Montserrat Medium";
			letter-spacing: 2px;
			text-transform: uppercase;
			font-size: 12px;
			font-weight: 600;
			line-height: 16px;
			color: #FB2D69;
			color: #ff0850;
			cursor: pointer;
			text-align: center;
			.text{
				margin-bottom: 5px;
			};
			.arraw{
				display: inline-block;
				cursor: pointer;
				position:relative;
				top: -10px;
				transition: .2s all linear;
			}
			&:hover{
				.arraw{
					position:relative;
					top: -5px;
				}
			}
		}
	}
	.block6{
		height: 600px;
		padding: 138px;
		background-size: 100% 100%;
		.inner-container{
			.info-block{
				overflow: hidden;
				box-sizing: border-box;
				border: solid 1px #fff;
				background: #fff;
				.inner-info-block{
					overflow: hidden;
					margin: 60px 0px 60px;
					margin: 60px 0px 50px;
					.inner-block{
						display: inline-block;
						text-align: center;
						width:80%;
						.p11{
							color: $dark-text-color;
							font-family: "Montserrat Regular";
							font-size: 24px;
							font-weight: 500;
							line-height: 34px;
							max-width: 270px;
							text-align: center;
							margin: 0 auto;
							margin-top: 25px;
							margin-bottom: 28px;
							padding: 0px;
							height: 68px;
							overflow: hidden;
						}
					}
					.button{
						cursor: pointer;
						width: 223px;
						height: 56px;
						color: #ffffff;
						font-family: ". Apple System UI Font";
						font-family: "Montserrat Regular";
						font-family: "Montserrat SemiBold";
						font-size: 12px;
						font-weight: 400;
						letter-spacing: 2px;
						text-transform: uppercase;
						outline: solid 0px #FFF;
						@include border-radius(3px);
					}
					.left{
						float:left;
						width:50%;
						height: 215px;
						border-right: solid 1px #ccc;
						padding-left: 60px;
						.button{
							outline: solid 0px #FFF;
							background-color: #fc2c69;
							border: 0px solid #fb2d69;
							line-height: 56px;
						}
						.linear{
							display: none;
						}
					}
					.right{
						float:left;
						width:50%;
						height: 205px;
						text-align: center;
						.button{
							display: block;
							margin: 0 auto;
							line-height: 52px;
							width: 223px;
							height: 56px;
							background: #fff;
							color: #fb2d69;
							border: 2px solid #fb2d69;
						}
					}
				}
			}
		}

	}
	.block7-table{
		padding-top: 124px;
		padding-bottom: 132px;
		h2.title{
			padding-bottom: 36px;
		}
		.mobile-table-container{
			display: none;
		}
		.inner-container{
			table{
				width: 100%;
				tr{
					td{
						padding: 1rem 0;
						height: 48px;
						padding: 0.5rem 0;
						color: $dark-text-color;
						font-family: "Montserrat Regular";
						font-size: 17px;
						font-weight: 400;
						line-height: 24px;
						span a{
							color: #7e7e94;
							font-family: "Montserrat Regular";
							font-size: 17px;
							font-weight: 400;
							line-height: 24px;
							text-decoration: underline;
							text-transform: uppercase;
						}
					}
					&:nth-child(2n) {
						background-color: #fbfbfc;
					}
				}
				.col1{
					padding-left: 10px;
				}
				.col2{
					width:100px;
					text-align: right;
					padding-right: 15px;
				}
			}
			.mobile-table{
				display: none;
			}
		}
		.more{
			display:block;
			margin: 0 auto;
			margin-top: 30px;
			margin-top: 48px;
			width: 100px;
			height: 50px;
			font-family: "Montserrat Medium";
			letter-spacing: 2px;
			text-transform: uppercase;
			font-size: 12px;
			font-weight: 600;
			line-height: 16px;
			color: #FB2D69;
			color: #ff0850;
			cursor: pointer;
			text-align: center;
			.text{
				margin-bottom: 5px;
			};
			.arraw{
				display: inline-block;
				cursor: pointer;
				position:relative;
				transition: .2s all linear;
				top: -4px;
				top: -10px;
			}
			&:hover{
				.arraw{
					position:relative;
					top: -5px;
				}
			}
		}
	}
	@media screen and (max-width: 1100px){

		.block1{
			.content-text{
			}
			.info-block{
				width: 59%;
				float: right;
				.inner-info-block{
					.left{
						float:left;
						width:65%;
						padding-left: 40px;

					}
					.right{
						//height: 181px;
						//float:left;
						width:35%;
					}

				}
			}
		}
		.block2{
			.inner-container{
				text-align: left;
			}
		}
		.block6{
			.inner-container{
				.info-block{
					.inner-info-block{
						//overflow: hidden;
						margin: 60px 0px 60px;
						.inner-block{
							width:80%;
						}
						.left{
							.inner-block{
								width:90%
							}
							padding-left: 20px;
						}
						.right{
							width:50%;
						}
					}
				}
			}

		}
		.block4{
			.inner-container{
				text-align: left;
			}
		}

	}
	//@media screen and (max-width: 992px){
	@media screen and (max-width: 992px){
		.block6{
			padding-left: 40px;
			padding-right: 40px;
		}
	}

	@media screen and (max-width: 767px){
		.top.wrapper{
			.hero.container{
				padding-top: 5.75rem;
				padding-top: 0px;
				.content{
					text-align: center;
					h1{
						color: #ffffff;
						font-family: "Montserrat Regular";
						font-size: 40px;
						font-weight: 400;
						line-height: 48px;
						text-align: center;
					}
					.subheader{
						color: #ffffff;
						font-family: "Montserrat Regular";
						font-size: 24px;
						font-weight: 400;
						line-height: 32px;
						text-align: center;
					}
				}
			}
		}
		.block-link{
			height: 64px;
			position: relative;
			padding: 0;
			.mobile-menu-block{
				padding: 0 2rem;
				display: none;
				width:100%;
				position: absolute;
				z-index: 1000;
				top:64px;
				background: #fff;
				border-bottom:solid 1px $border-color;
				-webkit-box-shadow: 0 3px 4px rgba(0, 0, 0, 0.21);
				-moz-box-shadow: 0 3px 4px rgba(0, 0, 0, 0.21);
				box-shadow: 0 3px 4px rgba(0, 0, 0, 0.21);
				.menu{
					list-style-type: none;
					li{
						display: block;
						padding-top: 12px;
						padding-bottom: 12px;
						a{
							color: #a3a3b4;
							padding-right: 32px;
							padding-right: 32px;
							font-family: "Montserrat Regular";
							font-family: "Montserrat SemiBold";
							font-size: 11px;
							font-weight: 300;
							letter-spacing: 2px;
							line-height: 11px;
							text-transform: uppercase;
						}
						&:first-child{
							padding-top: 20px;
						}
						&:last-child{
							padding-bottom: 38px;
						}
						&.active{
							a{
								color: #292d33;
							}
						}
					}
				}
				&.visible{
					display: block;
				}

			}
			.togle-button{
				padding: 0 2rem;
				cursor: pointer;
				display: inline-block;
				color: $dark-text-color;
				font-family: "Montserrat Regular";
				font-family: "Montserrat SemiBold";
				font-size: 11px;
				font-weight: 300;
				letter-spacing: 2px;
				line-height: 11px;
				text-transform: uppercase;
				padding-top: 29px;
				padding-bottom: 24px;
			}
			.menu-block{
				display: none;
			}

		}
		.block1{
			.container {
				padding-top: 64px;
			}
			.content-text{
				float: none;
				width: 327px;
				max-width: 100%;
				margin: 0 auto;
				.p1{
					padding-bottom: 31px;
				}

			}
			.info-block{
				overflow: hidden;
				box-sizing: border-box;
				width: 327px;
				float: none;
				max-width: 100%;
				height: auto;
				margin: 0 auto;
				.inner-info-block{
					margin: 0px;
					.left{
						float:none;
						width:100%;
						border-right: solid 0px #e7e7e7;
						padding-left: 32px;
						height: auto;
						.p11{
							padding-top: 10px;
						}
					}
					.right{
						display: none;
					}
					.mobile-right{
						display: block;
						padding-left: 32px;
						text-align: left;
						.left1{
							float:left;
							width:37%;
							.p11{
								margin-top: 20px;
								margin-bottom: 0px;
								color: $dark-text-color;
								font-family: "Montserrat Regular";
								font-size: 17px;
								font-weight: 500;

							}
							.text-blue{
								margin-top: 0px;
								margin-bottom: 27px;
								color: #68d0d0;
								font-family: "Montserrat Regular";
								font-size: 17px;
								font-weight: 300;
							}
						}
						.right1{
							float:left;
							width:50%;
							.p1{
								margin-top: 20px;
								margin-bottom: 0px;
								color: $dark-text-color;
								font-family: "Montserrat Regular";
								font-size: 17px;
								font-weight: 500;
							}
							.text-number{
								margin-top: 0px;
								color: $dark-text-color;
								font-family: "Montserrat Regular";
								font-size: 17px;
								font-weight: 300;
							}
						}
					}

				}
			}
		}
		.block2{
			padding-top: 27px;
			.seeall-block{
				width: 327px;
				max-width: 100%;
				margin: 0 auto;
				.seeall{
					margin-top: 35px;
					margin-bottom: -47px;
				}
				h2.title{
					margin-bottom: 32px;
				}
			}
			.inner-container{
				margin: 0 auto;
				.block-element-wrapper{
					display: block;
					width: 347px;
					margin: 0px auto;
					.block-element{
						width: 327px;
					}
				}
			}
		}
		.block3{
			.seeall-block-desk{
				display: block;
				width: 327px;
				max-width: 100%;
				margin: 0 auto;
				.seeall{
					display: none;
					margin: 0px;
				}
				h2.title{
					margin-bottom: -35px;
				}
			}
			.seeall-block-mob{
				display: block;
				width: 327px;
				max-width: 100%;
				margin: 0 auto;
				.seeall{
					margin:0px;
					text-align: left;
				}
				h2.title{
					margin-bottom: 50px;
					//margin-bottom: -20px;
				}
			}
			.inner-container{
				margin-left:0px;
				.block-element{
					display: block;
					width: 327px;
					max-width: 100%;
					margin: 0 auto;
					margin-bottom: 28px;
					margin-top: 28px;
					.text{
						width: 266px;
						max-width: 75%;
					}
					&:first-child{
						margin-top: 76px;
					}
				}
			}

		}
		.block4{
			padding-top: 50px;
			.title-block{
				width: 327px;
				max-width: 100%;
				margin: 0 auto;
				h2.title{
					padding-bottom: 46px;
				}
			}
			.inner-container{
				margin: 0 auto;
				.block-element-wrapper{
					display: block;
					width: 347px;
					margin: 0px auto;
					.block-element{
						//margin-left: 5px;
						width: 327px;
						//margin: 0px auto;
					}
				}
			}
		}
		.block5-table{
			padding-top: 48px;
			padding-bottom: 70px;
			overflow: hidden;
			.title-block{
				width: 327px;
				max-width: 100%;
				margin: 0 auto;
				h2.title {
					padding-bottom: 28px;
				}
			}
			.mobile-table-container{
				margin: 0 -2rem;
				display: block;
				.mobile-table{
					.tr{
						padding-top: 25px;
						padding-bottom: 24px;
						.inner-tr{
							display: block;
							width: 327px;
							max-width: 78%;
							margin: 0 auto;
							.date{
								height: 24px;
								color: $dark-text-color;
								font-family: "Montserrat Regular";
								font-size: 17px;
								font-weight: 500;
								line-height: 24px;
							}
							.text{
								height: 24px;
								color: $dark-text-color;
								font-family: "Montserrat Regular";
								font-size: 17px;
								font-weight: 400;
								line-height: 24px;
								margin-bottom: 12px;
							}
							.info{
								//margin-bottom: 52px;
								span a{
									padding-right: 35px;
									color: #7e7e94;
									font-family: "Montserrat Regular";
									font-size: 17px;
									font-weight: 400;
									line-height: 24px;
									text-decoration: underline;
								}

							}
						}

						&:nth-child(2n) {
							background-color: #fbfbfc;
						}
					}
				}
				.more{
					display:block;
					margin: 0 auto;
					margin-top: 30px;
					margin-top: 48px;
					width: 100px;
					height: 50px;
					font-family: "Montserrat Medium";
					letter-spacing: 2px;
					text-transform: uppercase;
					font-size: 12px;
					font-weight: 600;
					line-height: 16px;
					color: #FB2D69;
					color: #ff0850;
					cursor: pointer;
					text-align: center;
					.text{
						margin-bottom: 5px;
					};
					.arraw{
						display: inline-block;
						cursor: pointer;
						position:relative;
						transition: .2s all linear;
						top: -4px;
						top: -10px;
					}
					&:hover{
						.arraw{
							position:relative;
							top: 0px;
							top: -5px;
						}
					}
				}

			}
			.inner-container{
				display: none;
			}
		}
		.block6{
			padding-left: 15px;
			padding-right: 15px;
		}
		.block7-table{
			padding-top: 81px;
			padding-bottom: 40px;
			overflow: hidden;
			.title-block{
				width: 327px;
				max-width: 100%;
				margin: 0 auto;
				h2.title {
					padding-bottom: 24px;
				}
			}
			.inner-container{
				display: none;
				.mobile-table{
					display: block;
					width: 327px;
					max-width: 100%;
					margin: 0 auto;
					.tr{
						height: 108px;
						.text{
							color: $dark-text-color;
							font-family: "Montserrat Regular";
							font-size: 17px;
							font-weight: 500;
							line-height: 24px;
						}
						.info{
							span a{
								color: #7e7e94;
								font-family: "Montserrat Regular";
								font-size: 17px;
								font-weight: 400;
								line-height: 24px;
								text-decoration: underline;
								text-transform: uppercase;
							}
						}
						&:nth-child(2n) {
							background: #F8F9FC;
						}
					}
				}
			}
			.mobile-table-container{
				margin: 0 -2rem;
				display: block;
				.mobile-table{
					.tr{
						padding-top: 25px;
						padding-bottom: 24px;
						.inner-tr{
							display: block;
							width: 327px;
							max-width: 78%;
							margin: 0 auto;
							.date{
								height: 24px;
								color: $dark-text-color;
								font-family: "Montserrat Regular";
								font-size: 17px;
								font-weight: 500;
								line-height: 24px;
							}
							.text{
								height: 24px;
								color: $dark-text-color;
								font-family: "Montserrat Regular";
								font-size: 17px;
								font-weight: 400;
								line-height: 24px;
								margin-bottom: 12px;
							}
							.info{
								//margin-bottom: 52px;
								span a{
									padding-right: 15px;
									color: #7e7e94;
									font-family: "Montserrat Regular";
									font-size: 17px;
									font-weight: 400;
									line-height: 24px;
									text-decoration: underline;
									text-transform: uppercase;
								}

							}
						}

						&:nth-child(2n) {
							background-color: #fbfbfc;
						}
					}
				}
				.more{
					display:block;
					margin: 0 auto;
					margin-top: 30px;
					margin-top: 48px;
					width: 100px;
					height: 50px;
					font-family: "Montserrat Medium";
					letter-spacing: 2px;
					text-transform: uppercase;
					font-size: 12px;
					font-weight: 600;
					line-height: 16px;
					color: #FB2D69;
					color: #ff0850;
					cursor: pointer;
					text-align: center;
					.text{
						margin-bottom: 5px;
					};

					.arraw{
						display: inline-block;
						cursor: pointer;
						position:relative;
						top: -4px;
						top: -10px;
					}
					&:hover{
						.arraw{
							position:relative;
							top: 0px;
							top: -5px;
						}
					}
				}

			}

		}

	}
	@media screen and (min-width: 567px) and (max-width: 767px){
		.block2{
			.seeall-block{
				width: auto;
			}
			.inner-container{
				margin: 0 auto;
				.block-element-wrapper{
					display: inline-block;
					width: 50%;
					margin: 0px auto;
					.block-element{
						width: 94%;
					}
					&:nth-child(2n+1){
						margin-left: -10px;
					}
					&:nth-child(2n){
						margin-left: 10px;
					}
				}
			}
		}
		.block3{
			.seeall-block-desk{
				display: block;
				width: auto;
				.seeall{
					display: block;
					margin-bottom: -50px;
					margin-top: 45px;
				}
			}
			.seeall-block-mob{
				display: none;
			}
			.inner-container{
				margin-left:0px;
				padding-top: 76px;
				.block-element{
					display: inline-block;
					width: 50%;
					width: 48%;
					margin-bottom: 28px;
					margin-top: 28px;
					.text{
						width: 90%;
						max-width: 85%;
					}
					&:first-child{
						margin-top: 29px;
					}
					&:nth-child(2n){
						margin-left: 4%;
					}
				}
			}

		}
		.block4{
			.title-block{
				width: auto;
			}
			.seeall-block{
				width: auto;
			}
			.inner-container{
				.block-element-wrapper{
					display: inline-block;
					width: 236px;
					width: 50%;
					margin: 0px auto;
					.block-element{
						width: 94%;
					}
					&:nth-child(2n+1){
						margin-left: -10px;
					}
					&:nth-child(2n){
						margin-left: 10px;
					}
				}
			}
		}
		.block5-table{
			.title-block{
				width: 100%;
			}
			.mobile-table-container{
				margin: 0 -2rem;
				display: block;
				.mobile-table{
					.tr{
						padding-top: 25px;
						padding-bottom: 24px;
						.inner-tr{
							display: block;
							width: 100%;
							max-width: 85%;
						}
					}
				}

			}
			.inner-container{
				display: none;
			}
		}
		.block7-table{
			.title-block{
				width: 100%;
			}
			.inner-container{
				display: none;
			}
			.mobile-table-container{
				margin: 0 -2rem;
				display: block;
				.mobile-table{
					.tr{
						padding-top: 25px;
						padding-bottom: 24px;
						.inner-tr{
							display: block;
							width: 100%;
							max-width: 85%;
						}
					}
				}
			}

		}

	}

	@media screen and (max-width: 700px){
		.block6 {
			padding-left: 7px;
			padding-right: 7px;
		}
	}
	@media screen and (max-width: 567px){
		.block6{
			padding:0px;
			height: auto;
			background-size:100% 100%;
			.inner-container{
				width: 328px;
				max-width: 100%;
				margin: 32px auto;
				height: auto;
				.info-block{
					.inner-info-block{
						margin:0;
						.left{
							float:none;
							width:100%;
							height: auto;
							border: solid 0px #ccc;
							padding-left:0px;
							text-align: center;
							.inner-block{
								width:80%;
								.p11{
									max-width: 250px;
									margin-top: 64px;
									margin-bottom: 29px;
								}
							}
							.button{
								margin-bottom: 64px;
							}
							.linear{
								display:block;
								width:204px;
								margin: 0 auto;
								border-bottom: solid 1px #ccc;
							}
						}
						.right{
							height: auto;
							float:none;
							width:100%;
							.p11{
								margin-top: 62px;
								margin-bottom: 30px;
							}
							.button{
								margin-bottom: 64px;
							}
						}
					}
				}
			}
		}
	}
	@media screen and (max-height: 567px){
		.modal-window{
			.md-modal{
				.top{
					padding-top: 10px;
				}
				h3{
					margin-top: 0px;
					font-size: 28px;
				}
				p{
					margin-top: 0px;
					padding-bottom: 0px;
					margin-bottom: 5px;
				}
				.checkbox-block {
					margin-bottom: 0px;
				}
			}

		}
	}
	@media screen and (max-width: 480px){
		.top.wrapper{
			.hero.container{
				.content{
					h1{
						padding-left: 15px;
						padding-right: 15px;
					}
					.subheader{
						text-align: center;
						line-height: 24px;
					}
				}
			}
		}
		.block-link{
			.mobile-menu-block{
				padding: 0 1rem;
			}
			.togle-button{
				padding-left:  1rem;
			}
		}
	}
	@media screen and (max-width: 374px){
		.block2{
			.inner-container{
				.block-element-wrapper{
					display: block;
					width: 280px;
					margin: 0px auto;
					.block-element {
						width: 260px;
					}
				}
			}
		}
		.block4{
			.inner-container{
				margin-left: 0px;
				.block-element-wrapper{
					display: block;
					width: 280px;
					margin: 0px auto;
					.block-element {
						width: 260px;
					}
				}
			}
		}
	}
}
.styrelse{
	.top.wrapper{
		border-bottom: solid 1px $border-color;
		padding-bottom: 1.5rem;
		div.nav ul.menu > li > a{
			font-family: "Montserrat Medium";
		}
	}
	.article.container{
		font-family: "Montserrat Regular";
		padding-top:0px;
		.top-navigation{
			span a{
				padding-right: 10px;
				color: #a3a3b4;
				font-family: "Montserrat";
				font-family: "Montserrat Regular";
				font-size: 10px;
				font-weight: 300;
				letter-spacing: 2px;
				text-transform: uppercase;
				&:hover{
					color:$dark-blue;
				}
				&:last-child{
					padding-left: 7px;
				}
			}
			.sprite-arraw-grey{
				padding-left: 9px;
				padding-right: 0px;
				position:relative;
			}
		}
		.block-styrelse{
			padding-top: 88px;
				.mobile-aside-block{
					display: none;
				}
				.aside-block{
					overflow: hidden;
					display: block;
					float:left;
					width:298px;
					.aside-menu{
						margin: 0px;
						padding: 0px;
						li{
							list-style-type: none;
							line-height: 33px;
							a{
								//line-height: ;
								color: #a3a3b4;
								font-size: 17px;
								font-weight: 400;
								line-height: 21px;
								&:hover{
									color: $dark-blue;
								}
								&.active{
									color: #292d33;
									font-weight: 300;
									font-family: "Montserrat Medium";
								}
							}
						}
					}
				}
				.article-block{
					display: block;
					margin-left: 300px;
					max-width: 680px;
					max-width: 605px;
					h1{
						padding-top: 10px;
						color: #292d33;
						font-size: 48px;
						font-weight: 400;
						line-height: 48px;
					}
					.text{
						padding-bottom: 49px;
						color: #7c7c92;
						font-weight: 400;
						font-size: 17px;
						font-size: 20px;
						line-height: 32px;

					}
					.article-element{
						margin-bottom: 88px;
						margin-bottom: 105px;
						.left{
							float:left;
							width:150px;
							height: 150px;
						}
						.right{
							margin-left:150px;
							padding-left: 32px;
							h2{
								color: #292d33;
								font-size: 24px;
								font-weight: 500;
								line-height: 21px;
								font-family: "Montserrat Medium";
								margin-bottom:25px;
							}
							.p1{
								color: #7c7c92;
								font-weight: 400;
								font-size: 20px;
								line-height: 32px;
							}
							.info{
								font-size: 17px;
								line-height: 24px;
								font-size: 20px;
								line-height: 32px;
								margin-top: 23px;
								.aktier{
									color: #1f2226;
									font-weight: 500;
									font-family: "Montserrat Medium";
								}
								.amount{
									color: #7e7e94;
									font-weight: 400;
								}
							}
						}
						&:after{
							display: block;
							content: "";
							clear: both;
						}
					}
				}
			}
	}
	@media (max-width: 992px) {
		.article.container{
			.block-styrelse{
				.aside-block{
					width:198px;
				}
				.article-block{
					margin-left: 200px;
				}
			}
		}
	}
	@media (max-width: 767px) {
		.article.container{
			.block-styrelse{
				padding-top: 0px;
				.mobile-aside-block{
					display: block;
					float:none;
					position: relative;
					//width:100%;
					margin: 0 -2rem;
					.togle-button{
						//max-width: 425px;
						cursor: pointer;
						padding: 9px 2rem;
						border-bottom: solid 1px $border-color;
						.down-class{
							float: right;
							position: relative;
							top:15px;
						}
						.close{
							float: right;
							position: relative;
							top:10px;
						}
						.togle-button-text{
							float: left;
							font-size: 17px;
							&:hover{
								color: $dark-blue;
							}
						}
						&:hover{
							color: $dark-blue;
						}
						&:after{
							display: block;
							content:'';
							clear:both;
						}
					}
					.mobile-menu-block{
						display: none;
						position: absolute;
						z-index: 1000;
						background: #fff;
						border-bottom: solid 1px $border-color;
						box-shadow: 0 3px 4px rgba(0, 0, 0, 0.21);
						box-shadow: 0 3px 4px rgba(90, 90, 90, 0.1);
						width: 100%;
						top: 46px;
						.visible{
							display: block;
						}
						.aside-menu{
							margin: 0px;
							padding: 0px;
							li{
								list-style-type: none;
								line-height: 33px;
								padding: 0 2rem;
								margin-top: 0px;
								margin-bottom: 0px;
								padding-top:7px;
								padding-bottom:7px;
								a{
									//line-height: ;
									color: #a3a3b4;
									font-size: 17px;
									font-weight: 400;
									line-height: 21px;
									&.active{
										color: #292d33;
										font-weight: 300;
									}
								}
								&.active{
									background: #fc2c69;
									a{
										color:#fff;
									}
								}
							}
						}
					}
					&:after{
						display: block;
						content:'';
						clear:both;
					}
				}
				.aside-block{
					display: none;
					float:none;
					width:100%;
				}
				.article-block{
					margin-left: 0px;
					float:none;
					width:100%;
					h1{
						padding-top: 30px;
					}
				}
			}
		}
	}
	@media (max-width: 480px) {
		.article.container{
			.block-styrelse{
				.aside-block{
				}
				.article-block{
					.article-element{
						.left{
							width:100px;
							height: 100px;
							img{
								max-width: 100%;
							}
						}
						.right{
							margin-left:100px;
							padding-left: 32px;
							padding-left: 22px;
						}
					}
				}
			}
		}
	}

}
.rapporter{
	.top.wrapper{
		border-bottom: solid 1px $border-color;
		padding-bottom: 1.5rem;
	}

	.article.container{
		padding-top:0px;
		//font-family: "Avenir Next";
		font-family: "Montserrat Regular";
		.top-navigation{
			span a{
				padding-right: 10px;
				color: #a3a3b4;
				//font-family: "Avenir Next";
				font-family: "Montserrat Regular";
				font-size: 10px;
				font-weight: 300;
				letter-spacing: 2px;
				text-transform: uppercase;
				&:hover{
					color:$dark-blue;
				}
				&:last-child{
					padding-left: 7px;
				}
			}
			.sprite-arraw-grey{
				padding-left: 9px;
				padding-right: 0px;
				position:relative;
			}
		}
		.block-rapporter{
			padding-top: 90px;
			padding-bottom: 100px;
			h2.title{
				padding-bottom: 18px;
				color: #292d33;
				font-size: 48px;
				font-weight: 400;
				line-height: 48px;
			}
			.mobile-table-container{
				display: none;
			}
			.inner-container{
				padding-left: 11px;
				table{
					width: 100%;
					tr{
						td{
							padding: 1rem 0;
							height: 48px;
							padding: 0.5rem 0;
							color: $dark-text-color;
							font-family: "Montserrat Regular";
							font-size: 17px;
							font-weight: 400;
							line-height: 24px;
							span a{
								color: #7e7e94;
								font-family: "Montserrat Regular";
								font-size: 17px;
								font-weight: 400;
								line-height: 24px;
								text-decoration: underline;
								text-transform: uppercase;
							}
						}
						&:nth-child(2n) {
							background-color: #fbfbfc;
						}
					}
					.col1{
						padding-left: 10px;
					}
					.col2{
						width:100px;
						text-align: right;
						padding-right: 15px;
					}
				}
				.mobile-table{
					display: none;
				}
			}
			.more{
				display:block;
				margin: 0 auto;
				margin-top: 30px;
				margin-top: 48px;
				width: 100px;
				height: 50px;

				font-family: "Montserrat Medium";
				letter-spacing: 2px;
				text-transform: uppercase;
				font-size: 12px;
				font-weight: 600;
				line-height: 16px;
				color: #FB2D69;
				color: #ff0850;
				cursor: pointer;
				text-align: center;
				.text{
					margin-bottom: 5px;
				};

				.arraw{
					display: inline-block;
					cursor: pointer;
					position:relative;
					transition: .2s all linear;
					top: -4px;
					top: -10px;
				}
				&:hover{
					.arraw{
						position:relative;
						top: -5px;
					}
				}
			}
		}
	}

	@media (max-width: 767px) {
		.article.container{
			.block-rapporter{
				padding-top: 60px;
				.title-block{
					width: 327px;
					max-width: 100%;
					margin: 0 auto;
					h2.title {
						padding-bottom: 10px;
					}
				}
				.inner-container{
					display: none;
				}
				.mobile-table-container{
					margin: 0 -2rem;
					display: block;
					.mobile-table{
						.tr{
							padding-top: 25px;
							padding-bottom: 24px;
							.inner-tr{
								display: block;
								width: 327px;
								max-width: 78%;
								margin: 0 auto;
								.date{
									height: 24px;
									color: $dark-text-color;
									font-family: "Montserrat Regular";
									font-size: 17px;
									font-weight: 500;
									line-height: 24px;
								}
								.text{
									height: 24px;
									color: $dark-text-color;
									font-family: "Montserrat Regular";
									font-size: 17px;
									font-weight: 400;
									line-height: 24px;
									margin-bottom: 12px;
								}
								.info{
									//margin-bottom: 52px;
									span a{
										padding-right: 15px;
										color: #7e7e94;
										font-family: "Montserrat Regular";
										font-size: 17px;
										font-weight: 400;
										line-height: 24px;
										text-decoration: underline;
										text-transform: uppercase;
									}

								}
							}

							&:nth-child(2n) {
								background-color: #fbfbfc;
							}
						}
					}
					.more{
						display:block;
						margin: 0 auto;
						margin-top: 30px;
						margin-top: 48px;
						width: 100px;
						height: 50px;

						font-family: "Montserrat Medium";
						letter-spacing: 2px;
						text-transform: uppercase;
						font-size: 12px;
						font-weight: 600;
						line-height: 16px;
						color: #FB2D69;
						color: #ff0850;
						cursor: pointer;
						text-align: center;
						.text{
							margin-bottom: 5px;
						};

						.arraw{
							display: inline-block;
							cursor: pointer;
							position:relative;
							transition: .2s all linear;
							top: -4px;
							top: -10px;
						}
						&:hover{
							.arraw{
								position:relative;
								top: 0px;
								top: -5px;
							}
						}
					}

				}
			}
		}
	}

}
.pressmed{
	.top.wrapper{
		border-bottom: solid 1px $border-color;
		padding-bottom: 1.5rem;
	}
	.article.container{
		font-family: "Montserrat Regular";
		padding-top:0px;
		.top-navigation{
			span a{
				padding-right: 10px;
				color: #a3a3b4;
				font-size: 10px;
				font-weight: 300;
				letter-spacing: 2px;
				text-transform: uppercase;
				&:hover{
					color:$dark-blue;
				}
				&:last-child{
					padding-left: 7px;
				}
			}
			.sprite-arraw-grey{
				padding-left: 9px;
				padding-right: 0px;
				position:relative;
			}
		}
		.block-pressmed{
			padding-top: 88px;
			.mobile-aside-block{
				display: none;
			}
			.aside-block{
				overflow: hidden;
				display: block;
				float:left;
				width:298px;
				.aside-menu{
					margin: 0px;
					padding: 0px;
					li{
						list-style-type: none;
						line-height: 33px;
						a{
							color: #a3a3b4;
							font-size: 17px;
							font-weight: 400;
							line-height: 21px;
							&:hover{
								color: $dark-blue;
							}
							&.active{
								color: #292d33;
								font-weight: 300;
							}
						}
					}
				}
			}
			.article-block{
				display: block;
				margin-left: 300px;
				//padding-right: 132px;
				h1{
					padding-top: 10px;
					color: #292d33;
					font-size: 48px;
					font-weight: 400;
					line-height: 48px;
					padding-bottom: 40px;
				}
				.inner-container{
					text-align: left;
					margin-left:0px;
					.attachments{
						margin-top: 8em;
						margin-bottom: 30px;
						.title-block{
							//margin: 0;
						}
					}
					table{
						width: 100%;
						tr{
							td{
								padding: 1rem 0;
								height: 48px;
								padding: 0.5rem 0;
								color: $dark-text-color;
								font-family: "Montserrat Regular";
								font-size: 17px;
								font-weight: 400;
								line-height: 24px;
								span a{
									color: #7e7e94;
									font-family: "Montserrat Regular";
									font-size: 17px;
									font-weight: 400;
									line-height: 24px;
									text-decoration: underline;
									text-transform: uppercase;
								}
							}
							&:nth-child(2n) {
								background-color: #fbfbfc;
							}
						}
						.col1{
							padding-left: 10px;
						}
						.col2{
							width:100px;
							text-align: right;
							padding-right: 15px;
						}
					}
					.block-element{
						display: inline-block;
						margin-right: 30px;
						width: 360px;
						margin-bottom: 45px;
						text-align: left;
						vertical-align: top;
						.text{
							float:left;
							width: 320px;
							color: $dark-text-color;
							font-size: 17px;
							font-family: "Montserrat Medium";
							font-weight: 500;
							line-height: 24px;
							line-height: 24px;
							//max-height: 50px;
							margin-bottom: 5px;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: normal;
						}
						.date{
							font-size: 17px;
							font-weight: 400;
							color: #7e7e94;
							color: $dark-text-color;
						}
						.right{
							float: right;
							margin-right: 0px;
							width: 15px;
							margin-top: -7px;
							cursor: pointer;
							div{
								span{
									margin-left: 0px;
									transition-property: margin-left;
									transition-duration: 0.2s;
								}
							}
						}
						.date:before{
							display: block;
							content:"";
							clear: both;
						}
						.link-block-element{
							display: block;
							height: 100%;
							width: 100%;
							&:hover{
								.right{
									div{
										span{
											margin-left: 6px;
										}
									}

								}
							}
						}
					}
				}
			}
			.single-pr{
				max-width:776px;
				h1{
					font-size: 3rem;
					margin-top: 0;
					margin-bottom: 24px;
					font-weight: normal;
					line-height: 1.22em;
					padding-bottom: 15px;
				}
				p{
					font-family: "Montserrat Regular" !important;
					//font-size: 1.125rem;
					//line-height: 1.87rem;
					/*color:  #7e7e94!important;*//*колір у p*/
					/*color: #999999!important;*//*колір у span*/
					span{
						/*color: #999999!important;*/
					}
				}
			}
		}
	}
	@media (max-width: 992px) {
		.article.container{
			.block-pressmed{
				.aside-block{
					width:198px;
				}
				.article-block{
					margin-left: 200px;
				}
			}
		}
	}
	@media (max-width: 767px) {
		.article.container{
			.block-pressmed{
				padding-top: 38px;
				padding-top: 0px;
				.aside-block{
					display: none;
					float:none;
					width:100%;
				}
				.mobile-aside-block{
					display: block;
					float:none;
					position: relative;
					//width:100%;
					margin: 0 -2rem;
					.togle-button{
						//max-width: 425px;
						padding: 9px 2rem;
						border-bottom: solid 1px $border-color;
						.down-class{
							float: right;
							position: relative;
							top:15px;
						}
						.close{
							float: right;
							position: relative;
							top:12px;
						}
						.togle-button-text{
							float: left;
							font-size: 17px;
						}
						&:hover{
							color: $dark-blue;
						}
						&:after{
							display: block;
							content:'';
							clear:both;
						}
					}
					.mobile-menu-block{
						display: none;
						position: absolute;
						z-index: 10000;
						background: #fff;
						border-bottom: solid 1px $border-color;
						box-shadow: 0 3px 4px rgba(0, 0, 0, 0.21);
						box-shadow: 0 3px 4px rgba(90, 90, 90, 0.1);
						width: 100%;
						top: 46px;
						.visible{
							display: block;
						}
						.aside-menu{
							margin: 0px;
							padding: 0px;
							li{
								list-style-type: none;
								line-height: 33px;
								padding: 0 2rem;
								margin-top: 0px;
								margin-bottom: 0px;
								padding-top:7px;
								padding-bottom:7px;
								a{
									//line-height: ;
									color: #a3a3b4;
									font-size: 17px;
									font-weight: 400;
									line-height: 21px;
									//&:hover{
									//	color: $dark-blue;
									//}
									&.active{
										color: #292d33;
										font-weight: 300;
									}
								}
								&.active{
									background: #fc2c69;
									a{
										color:#fff;
									}
								}
							}
						}
					}
					&:after{
						display: block;
						content:'';
						clear:both;
					}
				}
				.article-block{
					display: block;
					margin-left: 0px;
					float:none;
					width:100%;
					h1{
						padding-top: 30px;
						padding-bottom: 35px;
					}
					.inner-container{
						.block-element{
							margin-bottom: 30px;
						}
					}
				}
			}
		}
	}
	@media (max-width: 480px) {
		.article.container{
			.block-pressmed{
				.article-block{
					h1 {
						font-size: 36px;
						line-height: 36px;
						padding-bottom: 0px;
					}
					.inner-container{
						.block-element{
							display: block;
							max-width: 100%;
							//margin: 0 auto;
							margin-bottom: 20px;
							.text{
								width: 266px;
								max-width: 75%;
							}
							&:first-child{
								margin-top: 36px;
							}
						}
					}
				}
			}
		}
	}

}
@media screen and (max-width: 767px) {
	.pressmed,
	.rapporter,
	.styrelse {
		.top.wrapper {
			div.nav {
				div.logo a:last-child{
					margin-top:2px
				}
			}
		}
	}
}

@media screen and (max-width: 480px) {
	.pressmed,
	.rapporter,
	.styrelse {
		.top.wrapper {
			padding-top: 5px;
			padding-bottom: 0;
			height: 64px;
			div.nav {
				padding-top: 6px;
				padding-bottom: 10px;
				height: 64px;
				div.logo a:last-child{
					margin-top:3px
				}
			}
		}
	}
}

.top-navigation1{
	border-bottom: solid 1px $border-color;
	margin: 0 -2rem;
	z-index: 9999;
	background: #fff;
	height: 48px;
	.top-navigation{
		margin: 0px 2rem;
		padding: 19px 0px 18px 0px;
		.article{
			font-family: "Montserrat SemiBold";
			font-size: 10px;
			line-height: 32px;
			line-height: 0px;
			a{
				padding-right: 10px;
				letter-spacing: 2px;
				text-transform: uppercase;
				font-family: "Montserrat SemiBold";
				font-size: 10px;
				font-weight: 600;
				line-height: 10px;
				letter-spacing: 2px;
				color: #7C7C92;
				&:hover{
					color:$dark-blue;
				}
				&:last-child{
					padding-left: 7px;
				}
			}
			span{
				a{
					padding-right: 10px;
					letter-spacing: 2px;
					text-transform: uppercase;
					font-family: "Montserrat SemiBold";
					font-size: 10px;
					font-weight: 600;
					line-height: 10px;
					letter-spacing: 2px;
					color: #7C7C92;
					&:hover{
						color:$dark-blue;
					}
					&:last-child{
						padding-left: 7px;
					}
				}
			}
			.sprite-arraw-grey{
				margin-left: 3px;
				margin-right: 3px;
				padding-left: 9px;
				padding-right: 0px;
				position:relative;
			}
		}
	}
}
@media (max-width: 767px){
	.top-navigation1 {
		margin: 0 -2rem;
		height: auto;
	}
}
@media (max-width: 480px){
	.top-navigation1 {
		margin: 0 -1rem;
		.top-navigation {
				margin: 0px 1rem;
		}
	}
}
//@media (min-width: 768px){
@media (min-width: 480px){
	//.fixed-block-container{
	//	width: 100%;
	//	height: 100%;
	//	min-height: 100%;
	//	max-width: 1680px;
	//	margin: 0px auto;
    //
	//	background: #fff;
	//	.content-for-fixed-footer{
	//		box-sizing: border-box;
	//		min-height: 100%;
	//		height: 100%;
	//		padding-bottom: 153px;
	//		overflow: auto;
	//		.fixed-content{
    //
	//		}
	//	}
	//	.footer {
	//		height: 153px;
	//		margin-top: -153px;
	//	}
	//}

	.fixed-block-container{
		width: 100%;
		height: 100%;
		min-height: 100%;
		max-width: 1680px;
		margin: 0px auto;

		background: #fff;
		.content-for-fixed-footer{
			box-sizing: border-box;
			min-height: 100%;
			padding-bottom: 153px;
			.fixed-content{
				min-height: 100%;
			}
		}
		.footer {
			height: 153px;
			margin-top: -153px;
		}
	}



}
//.fixed-content{
//	overflow: hidden;
//}
//.pressmed,
//.rapporter,
//.styrelse {
//	overflow: hidden;
//}

.investor-scroll{
	.top-navigation1.fixed-breadcrumbs{
		position: fixed;
		top:0px;
		width: 100%;
		max-width: 1680px;
	}
	.investro {
		.block-link.fixed-menu{
			max-width: 1680px;
			position: fixed;
			top:0px;
		}
	}
}
@media (min-width: 1680px){
	.investor-scroll{
		.investro {
			.block-link.fixed-menu{
				width: 1679px;
				max-width: 1680px;
				position: fixed;
				top:0px;
				left:50%;
				margin-left:-847px;
				overflow: hidden;
			}
		}
	}
}

